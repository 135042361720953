import axios from "axios";
// export default function request(config: any) {
const instance = axios.create({
  baseURL: "/api",
  withCredentials: true,
  timeout: 6000,
});
// 2.1.请求拦截的作  用
let token =
  "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9ncmF5LnRpbmdndXBpYW8uY29tLmNuXC9hcGlcL2F1dGhcL2xvZ2luIiwiaWF0IjoxNjYwMTE1Mjk2LCJleHAiOjE2OTY0MDMyOTYsIm5iZiI6MTY2MDExNTI5NiwianRpIjoiWGVZRHZzdm5XNmlUcnNFdSIsInN1YiI6MTkwMzQsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.2M7whwNF0-wOCKXADj5T7cSaMbhT24ewh_lUSdzdWQ0";

instance.interceptors.request.use(
  (config) => {
    config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    // config.headers.Authorization = token;
    // referer;
    // config.headers["referer"] = "https://pay.tinggupiao.com.cn";
    return config;
  },
  (err) => {
    console.log(err);
  }
);
// 2.2.响应拦截
instance.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (err) => {
    // eslint-disable-next-line no-mixed-operators
    const code = parseInt((err.response && err.response.status) || err.code);
    if (code === 400) {
      return Promise.reject(err.response.data);
    }
    if (code !== 200) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        message: err.response.data.message || "网络错误，请稍候再试",
        code: err.response.data.status_code || 500,
      });
    }
  }
);
// 3.发送真正的网络请求
//   return instance(config);
// }

export default function request({
  method = "get",
  url,
  data = {},
  params = {},
}) {
  if (method == "get") {
    params = data;
  }
  console.log(url, "configconfig");
  return instance({
    method,
    url,
    data,
    params,
  });
}
