import request from "./request";
import qs from "qs";
// 领取分享的体验产品;
export function receiveTryProduct(data) {
  return request({
    url: "/v4/work/receiveTryProduct",
    method: "post",
    data: qs.stringify(data),
  });
}
export function pay(data) {
  return request({
    url: `/orders/${data.order_id}/pay?channel=weixin_wap`,
    method: "get",
    data,
  });
}
export function paystatus(data) {
  return request({
    url: `/orders/${data.orderId}/status`,
    method: "get",
  });
}

export function scrmGetOpenid() {
  return request({
    url: `/v4/wechat/scrmGetOpenid`,
    method: "get",
  });
}
export function hasProductOrder(data) {
  return request({
    url: `/v4/work/hasProductOrder`,
    method: "post",
    data,
  });
}

export function getinfo(data) {
  return request({
    url: `/v4/product/getInfo`,
    method: "get",
    data,
  });
}

// 优惠券
export function getNoUseRecord(data) {
  return request({
    url: "/v4/coupon/getNoUseRecord",
    method: "get",
    data,
  });
}

export function buyProduct(data) {
  console.log(data, "ssssssss");
  return request({
    url: "/v4/work/buyProduct",
    method: "post",
    data: qs.stringify(data),
  });
}
